/*
  Project: Onkoskaner
  Author: WebCrafters Studio
 */
import Swiper, { Autoplay } from 'swiper';
import blockSlider from './modules/blocks/block-slider';
import blockPartners from './modules/blocks/block-partners';
import blockPosts from './modules/blocks/block-posts';
import blockStats from './modules/blocks/block-stats';
import './modules/select';
import './modules/main-nav';

document.addEventListener('DOMContentLoaded', () => {
	blockSlider(Swiper, Autoplay);
	blockPartners(Swiper, Autoplay);
	blockPosts(Swiper, Autoplay);
	blockStats();

	// Prevent empty links
	const links = document.querySelectorAll('a[href="#"]');
	links.forEach((el) => {
		const link = el;
		link.onclick = (e) => {
			e.preventDefault();
		};
	});
});
