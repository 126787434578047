function closeAllSelect(elmnt) {
	/* A function that will close all select boxes in the document,
  except the current select box: */
	let x;
	let y;
	let i;
	let xl;
	let yl;
	const arrNo = [];
	x = document.getElementsByClassName('c-form__select-items');
	y = document.getElementsByClassName('c-form__selected');
	xl = x.length;
	yl = y.length;
	for (i = 0; i < yl; i++) {
		if (elmnt == y[i]) {
			arrNo.push(i);
		} else {
			y[i].classList.remove('c-form__selected--arrow-active');
		}
	}
	for (i = 0; i < xl; i++) {
		if (arrNo.indexOf(i)) {
			x[i].classList.add('hidden');
		}
	}
}

let x;
let i;
let j;
let l;
let ll;
let selElmnt;
let a;
let b;
let c;
/* Look for any elements with the class "custom-select": */
x = document.getElementsByClassName('js-custom-select');
l = x.length;
for (i = 0; i < l; i++) {
	selElmnt = x[i].getElementsByTagName('select')[0];
	ll = selElmnt.length;
	/* For each element, create a new DIV that will act as the selected item: */
	a = document.createElement('DIV');
	a.setAttribute('class', 'c-form__selected');
	a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
	x[i].appendChild(a);
	/* For each element, create a new DIV that will contain the option list: */
	b = document.createElement('DIV');
	b.setAttribute('class', 'c-form__select-items hidden');
	for (j = 0; j < ll; j++) {
		/* For each option in the original select element,
    create a new DIV that will act as an option item: */
		c = document.createElement('DIV');
		c.innerHTML = selElmnt.options[j].innerHTML;
		c.addEventListener('click', function (e) {
			/* When an item is clicked, update the original select box,
        and the selected item: */
			let y;
			let i;
			let k;
			let s;
			let h;
			let sl;
			let yl;
			s = this.parentNode.parentNode.getElementsByTagName('select')[0];
			sl = s.length;
			h = this.parentNode.previousSibling;
			for (i = 0; i < sl; i++) {
				if (s.options[i].innerHTML == this.innerHTML) {
					s.selectedIndex = i;
					h.innerHTML = this.innerHTML;
					y = this.parentNode.getElementsByClassName('same-as-selected');
					yl = y.length;
					for (k = 0; k < yl; k++) {
						y[k].removeAttribute('class');
					}
					this.setAttribute('class', 'same-as-selected');
					break;
				}
			}
			h.click();
			const event = new Event('select');
			h.dispatchEvent(event);
		});
		b.appendChild(c);
	}
	x[i].appendChild(b);
	a.addEventListener('click', function (e) {
		/* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
		e.stopPropagation();
		this.nextSibling.classList.toggle('hidden');
		this.classList.toggle('c-form__selected--arrow-active');
	});
}

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
// document.addEventListener('click', closeAllSelect);
