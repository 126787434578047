const blockPosts = (Swiper, Autoplay) => {
	const block = '.js-swiper-posts';
	const blockContainer = document.querySelector(block);
	if (!blockContainer) return false;

	let swiper = '';

	const swiperParams = {
		modules: [Autoplay],
		pagination: false,
		centeredSlides: false,
		navigation: false,
		autoplay: {
			delay: 3000,
		},
		slidesPerView: 1,
		// setWrapperSize: true,
		// roundLengths: true,
		autoHeight: false,
		spaceBetween: 30,
		loop: false,
		breakpoints: {
			992: {
				slidesPerView: 3,
			},
		},
	};

	swiper = new Swiper(block, swiperParams);

	return swiper;
};
export default blockPosts;
