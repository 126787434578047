const blockPartners = (Swiper, Autoplay) => {
	const block = '.js-swiper-partners';
	const blockContainer = document.querySelector(block);
	if (!blockContainer) return false;

	let swiper = '';

	const swiperParams = {
		modules: [Autoplay],
		pagination: false,
		centeredSlides: false,
		navigation: false,
		autoplay: {
			delay: 3000,
		},
		slidesPerView: 2,
		spaceBetween: 0,
		loop: true,
		breakpoints: {
			768: {
				slidesPerView: 4,
			},
			992: {
				slidesPerView: 6,
			},
		},
	};

	swiper = new Swiper(block, swiperParams);

	return swiper;
};
export default blockPartners;
