const blockStats = () => {
	const block = document.querySelector('.js-block-stats');
	if (!block) return;
	const blockContainer = block.querySelector('.c-form__selected');
	const select = document.getElementById('stats');

	const content = block.querySelectorAll('[data-stats]');
	blockContainer.addEventListener('select', () => {
		content.forEach((el) => {
			el.classList.add('d-none');
		});

		const { value } = select;
		const active = block.querySelector(`[data-stats="${value}"]`);
		active.classList.remove('d-none');
	});
};
export default blockStats;
